/**
 * List
 */

import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import convertWpUrlToPath from '@utils/convertWpUrlToPath';
import { Link, ContentParser } from '@components';


const List = ({ items, list_type, meta: { pageChildren } }) => {

  const getItems = () => {
    // List child pages
    if (list_type === 'child_pages' && Array.isArray(pageChildren)) {
      return pageChildren.map(page => ({
        label: get(page, 'acf.list_title', null) || get(page, 'acf.page_header_primary_title', null),
        subtitle: get(page, 'acf.list_subtitle', null) || get(page, 'acf.page_header_secondary_title', null),
        link_to: 'page',
        page: page.path,
      }))
    }

    // Manually added items
    if (list_type === 'manual' || Array.isArray(items)) {
      return items;
    }
    
    return [];
  };

  return (
    <div className="page__block--cards-list">
      {
        getItems()
        .filter(item => !item.hidden)
        .map((item, index) => {
          const href = item.link_to === 'page' && item.page ? convertWpUrlToPath(item.page) : item.external_url;

          return (
            <Link className="card card--preview" href={href} key={index}>
              {item.label && (<ContentParser element="div" className="card--preview__title" content={item.label} />)}
              {item.subtitle && (<ContentParser element="div" className="card--preview__subtitle" content={item.subtitle} />)}
            </Link>
          );
        })
      }
    </div>
  );
}

List.propTypes = {
  list_type: PropTypes.string,
  items: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.node,
            subtitle: PropTypes.string,
            url: PropTypes.string,
          })
      )
}

export default List;

export const pageBuilderListQuery = graphql`
  fragment PageBuilderList on WordPressAcf_list {
    ... on WordPressAcf_list {
      list_type
      items {
        hidden
        label
        subtitle
        sub_subtitle
        link_to
        external_url
        page
      }
    }
  }
`